<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >

      <div
        class="d-flex justify-content-end"
        style="gap: 5px"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          v-b-modal.modal-success
          variant="outline-success"
          class="btn-sm"
          @click="handleModalExcel"
        >
          <feather-icon
            icon="FileTextIcon"
            size="16"
          />
          Nhập file Excel
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-edit
          variant="danger"
          class="btn-sm"
          @click="resetModal(true)"
        >
          {{ $t('Add') }}
        </b-button>
      </div>
    </b-col>
    <b-col
      cols="12"
      class="form_custom"
    >
      <b-card-code title="">
        <b-row>
          <b-col cols="6">
            <b-button
              variant="outline-danger"
              class="position-absolute position-bottom-0 btn-sm"
              :disabled="!ids.length"
              @click="deleteMulti"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col cols="3" />
          <b-col cols="3">
            <label>
              Tag
            </label>
            <v-select
              v-model="filter.tag_id"
              :reduce="label => label.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="tagOptions"
              taggable
              :clearable="false"
              @input="loadItems"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th width="10">
                    <b-form-checkbox
                      v-model="cbxAll"
                      class="custom-control-danger"
                      name="check-button"
                      @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th
                    scope="col"
                    width="100"
                  >
                    Tag
                  </th>
                  <th
                    scope="col"
                    width="100"
                    class="text-center"
                  >
                    Điểm đánh giá
                  </th>
                  <th
                    scope="col"
                    class="text-center"
                  >
                    Nhận xét
                  </th>
                  <th width="10" class="text-center">
                    {{ $t("opiton") }}
                  </th>
                </tr>
              </thead>
              <template v-if="rows.length">
                <template v-if="isLoading">
                  <tr
                    v-for="(item) in rows"
                  >
                    <td colspan="1">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="3">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="2">
                      <b-skeleton animation="fade" />
                    </td>
                  </tr>
                </template>
                <tr
                  v-for="(item, index) in rows"
                  v-else
                  :id="item.id"
                  :key="item.id"
                >
                  <td>
                    <b-form-checkbox
                      v-model="ids"
                      :value="item.id"
                      class="custom-control-danger"
                      name="check-button"
                    />
                  </td>
                  <td>
                    <b-badge
                      variant="light-danger"
                      @click="resetModal(false, item)"
                    >
                      {{ item.tag.name }}
                    </b-badge>
                  </td>
                  <td class="text-center">
                    {{ item.point }}%
                  </td>
                  <td><span v-html="item.comment" /></td>
                  <td class="text-center">
                    <b-dropdown
                      id="dropdown-1"
                      class="custom-position-dropdown"
                      variant="flat-sencondary"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle"
                        />
                      </template>
                      <b-dropdown-item
                        v-b-modal.modal-edit
                        @click="resetModal(false, item)"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Sửa</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteMulti(item.id)">
                        <feather-icon icon="Trash2Icon" />
                        <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <tr
                v-else
              >
                <td colspan="8">
                  <p class="text-center">
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </b-card-code>
      <b-modal
        id="modal-edit"
        v-model="modalShow"
        :ok-title="$t('save_info')"
        ok-variant="danger"
        :cancel-title="$t('cancel')"
        centered
        size="lg"
        :title="editModal ? 'Chỉnh sửa' : titleAdd"
        @ok.prevent="submit "
      >
        <validation-observer ref="addReview">
          <b-form class="form_custom">
            <b-row>
              <b-col md="7">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="tag"
                    rules="required"
                  >
                    <label :class="errors.length > 0 ? 'text-danger': null">
                      Tag <span class="text-danger">(*)</span></label>
                    <v-select
                      id="tag"
                      v-model="frm.tag"
                      :reduce="label => label.id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="tagOptions"
                      taggable
                      :create-option="org => ({ id: org , name: org})"
                      push-tags
                      :state="errors.length > 0 ? false:null"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('point')"
                    rules="required"
                  >
                    <label :class="errors.length > 0 ? 'text-danger': null">
                      Điểm đánh giá(%) <span class="text-danger">(*)</span></label>
                    <b-form-input
                      id="point"
                      v-model="frm.point"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="nhận xét"
                  >
                    <div>
                      <label :class="errors.length > 0 ? 'text-danger': null">Nhận xét <span class="text-danger">(*)</span></label>
                      <quill-editor
                        v-model="frm.comment"
                        :options="snowOption"
                        :state="errors.length > 0 ? false:null"
                        max-rows="6"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
      <b-modal
        v-model="modalShowExcel"
        cancel-variant="outline-secondary"
        cancel-title="Huỷ"
        centered
        title="Nhập file Excel"
        size="lg"
        @ok.prevent="handleUploadTagReviews"
      >
        <b-row>
          <b-col md="12">
            <b-form-group>
              <div>
                <a
                  :href="endPoint + '/excels/Tag-cau-hoi.xlsx'"
                  class="float-right mb-2"
                >
                  <feather-icon icon="DownloadIcon" />
                  Tải file mẫu </a>
                <b-form-file
                  v-model="fileExcel"
                  placeholder="Tải file hoặc kéo và thả Excel"
                  accept=".xlsx, .xls, .csv"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import {
  min_value,
  required,
} from '@validations'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BEmbed, VBTooltip,
  BSkeleton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BSkeleton,
    BImg,
    BRow,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      cbxAll: false,
      editModal: false,
      modalShowExcel: false,
      modalShow: false,
      currentPage: 1,
      fileExcel: null,
      frm: {
        tag: null,
        point: null,
        comment: null,
      },
      pageLength: 10,
      isShow: true,
      dragging: false,
      tagOptions: [],
      titleAdd: this.$t('Add'),
      required,
      min_value,
      isLoading: false,
      total: 0,
      rows: [],
      ids: [],
      filter: {
        tag_id: null,
      },
      modalName: '',
      snowOption: {
        theme: 'snow',
        placeholder: 'Nhập nhận xét',
        modules: {
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
          ],
        },
      },
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      sort: {
        by: 'order',
        direction: 'desc',
      },

    }
  },
  watch: {
    ids: {
      handler(value) {
        this.cbxAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadItems()
    this.loadTags()
  },
  methods: {
    handleModalExcel() {
      this.modalShowExcel = true
    },
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/reviews', {
            data: {
              ids: this.ids,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadItems()
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              console.log('e', e)
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
              this.ids = []
            })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }
    },
    handleUploadTagReviews() {
      const formData = new FormData()
      if (this.fileExcel) {
        formData.append('fileExcel', this.fileExcel)
        const url = 'reviews/upload-excel'
        this.$http({
          method: 'post',
          url,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.modalShowExcel = false
            this.fileExcel = null
            this.loadItems()

            this.$bvToast.toast('Upload thành công', {
              title: this.$t('message.notify'),
              icon: 'BellIcon',
              variant: 'success',
              solid: false,
            })
          })
        // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.$bvToast.toast('Upload thất bại', {
              title: this.$t('message.notify'),
              icon: 'BellIcon',
              variant: 'danger',
              solid: false,
            })
          }).finally(() => {
            this.isLoading = false
          })
      }
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/reviews', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          tag_id: this.filter.tag_id,
          order_by: this.sort.by,
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.status = v.status === 1
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    loadTags() {
      this.isLoading = true
      this.$http.get('/tags', {
        params: {
          type: 1,
          perPage: 100,
        },
      })
        .then(res => {
          this.tagOptions = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    resetModal(flag, review = false) {
      if (flag) {
        this.editModal = false
        this.frm.tag = this.frm.comment = this.frm.point = null
      } else {
        this.editModal = review.id
        this.frm.tag = review.tag.id
        this.frm.point = review.point
        this.frm.comment = review.comment
      }
    },
    getSortDirection(sortBy) {
      if (sortBy === this.sort.by) {
        return this.sort.direction
      }

      return 'none'
    },
    changeSortDirection(sortBy) {
      if (this.sort.by !== sortBy) {
        this.sort.direction = 'none'
        this.sort.by = sortBy
      }
      if (this.sort.direction === 'none') {
        this.sort.direction = 'ascending'
      } else if (this.sort.direction === 'ascending') {
        this.sort.direction = 'descending'
      } else {
        this.sort.by = 'order'
        this.sort.direction = 'desc'
      }
      this.loadItems()
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    submit() {
      this.$refs.addReview.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()
          // eslint-disable-next-line no-unused-expressions
          typeof (this.frm.tag) === 'string'
            ? formData.append('newTag', this.frm.tag)
            : formData.append('tag_id', this.frm.tag)
          formData.append('comment', this.frm.comment)
          formData.append('point', this.frm.point)

          const url = this.editModal ? `/reviews/${this.editModal}` : '/reviews'
          if (this.editModal) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.modalShow = false
              this.loadItems()
              if (typeof (this.frm.tag) === 'string') {
                this.loadTags()
              }
              this.resetModal(true)

              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
